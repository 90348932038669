export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0,2,3,5,6,7,8];

export const dictionary = {
		"/(marketing)": [40,[14]],
		"/(marketing)/blog": [41,[14,15]],
		"/(marketing)/blog/posts/[slug=blogpost]": [42,[14,15]],
		"/(marketing)/contact_us": [43,[14]],
		"/(docs)/docs": [31,[9]],
		"/(docs)/docs/[...slug=doc]": [32,[9]],
		"/(authorised)/github/app_setup": [17,[2,3]],
		"/(login)/login": [~33,[10,11]],
		"/(login)/login/authenticate": [34,[10,11,12]],
		"/(login)/login/current_password_error": [35,[10,11]],
		"/(login)/login/forgot_password": [36,[10,11]],
		"/(login)/login/oauth": [37,[10,11]],
		"/(login)/login/register": [38,[10,11,13]],
		"/(oauth-provider)/oauth2/auth": [~45],
		"/(authorised)/onboarding/select_plan": [~18,[2,4]],
		"/(authorised)/onboarding/subscriptions/confirm/[slug]": [~19,[2,4]],
		"/(authorised)/onboarding/subscriptions/do_assignment": [~20,[2,4]],
		"/(authorised)/onboarding/subscriptions/do_subscribe/[slug]": [~21,[2,4]],
		"/(authorised)/onboarding/subscriptions/on_succeeded": [~22,[2,4]],
		"/(marketing)/pricing": [~44,[14]],
		"/(authorised)/settings": [23,[2,5]],
		"/(authorised)/settings/account": [24,[2,5,6]],
		"/(authorised)/settings/api": [25,[2,5]],
		"/(authorised)/settings/org/[org_id]/project": [26,[2,5,7]],
		"/(authorised)/settings/subscriptions": [~27,[2,5]],
		"/(login)/sign_out": [39,[10]],
		"/(authorised)/submit_feedback": [28,[2]],
		"/(authorised)/testpages/gh_token_refresh": [29,[2,8]],
		"/(authorised)/testpages/logevents/view": [30,[2]],
		"/(policies)/[slug=policydoc]": [46,[16]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';